body, input, select, textarea, button, .ui-btn {
  font-size: 1em;
  line-height: 1.3;
  font-family: sans-serif;
}
html {
  font-size: 100%;
}

a.wulink:link {
  text-decoration: none;
  text-shadow: none;
  color: #1F99E0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.02em;
}

a.wulink:visited {
  text-decoration: none;
  text-shadow: none;
  color: #1F99E0;
}

a.wulink:hover {
  text-decoration: none;
  text-shadow: none;
  color: #e76655;
}

a.wulink:active {
  text-decoration: none;
  text-shadow: none;
  color: #e76655;
}

.fonttest {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
}

.monty {
  font-family: 'Avenir Next', sans-serif;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: -0.01em;
  color: #333333;
}

.montyhead {
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #000000;
}

.montysub {
  font-family: 'Avenir Next', sans-serif;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #000000;
}

.montybutt {
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  padding: 10px 15px 10px 15px;
  font-weight: 400;
  letter-spacing: 0.01em;
}

.monty2 {
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0.01em;
  color: #666666;
}

.divhover {
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px #ffffff solid;
  transition: all 0.2s ease;
  background-color: #ffffff;
  color: #333333;
}

.divhover:hover {
  background-color: #e76655;
  color: #ffffff;
}

.divhover2 {
  border-radius: 5px;
  border: 1px #ffffff solid;
  transition: all 0.2s ease;
  background-color: #f84f40;
  color: #ffffff;
}

.divhover2:hover {
  background-color: #ffffff;
  color: #f84f40;
}

.divhoverblue {
  border-radius: 5px;
  border: none;
  transition: all 0.2s ease;
  background-color: #166aa1;
  color: #ffffff;
}

.divhoverblue:hover {
  background-color: #155d8c;
}

.rentbutton {
  border-radius: 10px;
  padding: 15px;
  border: none;
  transition: all 0.2s ease;
  background-color: #005dab;
  color: #ffffff;
}

.rentbutton:hover {
  background-color: #3588cd;
  color: #ffffff;
}

.montybutt2 {
  font-family: 'Avenir Next', sans-serif;
  font-size: 15px;
  padding: 13px 15px 13px 15px;
  font-weight: 400;
  letter-spacing: 0.01em;
}

input[type=text],
input[type=tel] {
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px !important;
  color: #333333;
  font-weight: 400;
  padding: 10px;
  border: none;
  border-radius: 0 !important;
  appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-color: #ffffff !important;
}

::placeholder {
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px !important;
  font-weight: 300;
  padding: 0px;
  color: #999999;
  letter-spacing: 0.01em;
}

.greenhover {
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px !important;
  color: #ffffff;
  font-weight: 600;
  padding: 13px 30px 13px 30px;
  border: none;
  border-radius: 0 !important;
  appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-color: #67cb33 !important;
  text-align: center;
  transition: background-color 0.2s ease;
}

.greenhover:not(:disabled):hover {
  background-color: #fdb539 !important;
}

sup {
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  vertical-align: super;
}

.fixo {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 80px;
  background-color: #f84f40;
  color: #000000;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03em;
  padding: 20px 20px 20px 20px;
  z-index: 20;
}

.fixtop {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  color: #000000;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03em;
  z-index: 20;
}




.radios .radio {
  background-color: #cccccc;
  display: inline-block;
  width: 175px;
  font-family: 'Avenir Next', Helvetica, sans-serif;
  font-size: 14px;
  text-align: center;
  margin-right: 5px;
  height: 100px;
  border: 3px solid #005dab;
  border-radius: 10px;
  cursor: pointer;
}

.radios input[type=radio] {
  display: none;
}

.radios input[type=radio]:checked+.radio {
  background-color: #ffffff;
  border: 3px solid #67cb33;
  position: relative;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width:550px) {
  .mob {
    display: block;
  }

  .tab {
    display: none;
  }

  .pc {
    display: none;
  }

  .storypad {
    padding: 20px 40px 20px 40px;
  }

  .arc {
    width: 100%;
    min-width: 270px;
    max-width: 280px;
  }

  .greenhover {
    font-family: 'Avenir Next', sans-serif;
    font-size: 15px !important;
    color: #ffffff;
    font-weight: 600;
    padding: 14px 30px 14px 30px;
    border: none;
    border-radius: 0 !important;
    appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    background-color: #67cb33 !important;
    text-align: center;
    transition: background-color 0.2s ease;
  }

  .greenhover:hover {
    background-color: #e1b345 !important;
  }



  .radios .radio {
    background-color: #cccccc;
    display: inline-block;
    width: 98%;
    font-family: 'Avenir Next', Helvetica, sans-serif;
    font-size: 12px;
    text-align: center;
    margin-right: 2px;
    height: 90px;
    border: 3px solid #005dab;
    border-radius: 10px;
    cursor: pointer;
  }

  .radios input[type=radio] {
    display: none;
  }

  .radios input[type=radio]:checked+.radio {
    background-color: #ffffff;
    border: 3px solid #67cb33;
  }



}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 551px) {
  .mob {
    display: none;
  }

  .tab {
    display: block;
  }

  .pc {
    display: none;
  }

  .storypad {
    padding: 40px 75px 40px 75px;
  }

  .arc {
    width: 60%;
    min-width: 320px;
    max-width: 360px;
  }

  .radios .radio {
    background-color: #cccccc;
    display: inline-block;
    width: 98%;
    font-family: 'Avenir Next', Helvetica, sans-serif;
    font-size: 14px;
    text-align: center;
    margin-right: 5px;
    height: 110px;
    border: 3px solid #005dab;
    border-radius: 10px;
    cursor: pointer;
  }

  .radios input[type=radio] {
    display: none;
  }

  .radios input[type=radio]:checked+.radio {
    background-color: #ffffff;
    border: 3px solid #67cb33;
  }



}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1000px) {
  .mob {
    display: none;
  }

  .tab {
    display: none;
  }

  .pc {
    display: block;
  }

  .storypad {
    padding: 60px 100px 60px 100px;
  }

  .arc {
    width: 70%;
    min-width: 580px;
    max-width: 620px;
  }

  .radios .radio {
    background-color: #cccccc;
    display: inline-block;
    width: 98%;
    font-family: 'Avenir Next', Helvetica, sans-serif;
    font-size: 14px;
    text-align: center;
    margin-right: 5px;
    height: 100px;
    border: 3px solid #005dab;
    border-radius: 10px;
    cursor: pointer;
  }

  .radios input[type=radio] {
    display: none;
  }

  .radios input[type=radio]:checked+.radio {
    background-color: #ffffff;
    border: 3px solid #67cb33;
  }


}


	
body {
  margin:0px;
  padding:0px;
  text-decoration:none;
}


a.wulink:link {
  text-decoration:none;
  text-shadow:none;
  color:#1F99E0;
  font-size:14px;
  font-weight:600;
  letter-spacing:0.02em;
}

a.wulink:visited {
  text-decoration:none;
  text-shadow:none;
  color:#1F99E0;
}

a.wulink:hover {
  text-decoration:none;
  text-shadow:none;
  color:#e76655;
}

a.wulink:active {
  text-decoration:none;
  text-shadow:none;
  color:#e76655;
}

.fonttest {
  font-family:'Poppins', sans-serif;
  font-size:30px;
  line-height:30px;
  font-weight:500;
  letter-spacing: 0.01em;
  color:#000000;
}
  
  .monty {
  font-family:'Avenir Next', sans-serif;
  font-size:17px;
  font-weight:400;
  letter-spacing: -0.01em;
  color:#333333;
}

   .montyhead {
  font-family:'Avenir Next', sans-serif;
  font-weight:600;
  letter-spacing: 0.01em;
  color:#000000;
}

   .montysub {
  font-family:'Avenir Next', sans-serif;
  font-size:30px;
  font-weight:600;
  letter-spacing: 0.01em;
  color:#000000;
}

  .montybutt {
  font-family:'Avenir Next', sans-serif;
  font-size:12px;
  padding:10px 15px 10px 15px;
  font-weight:500;
  letter-spacing: 0.01em;
}

.monty2 {
  font-family:'Avenir Next', sans-serif;
  font-size:13px;
  line-height:18px;
  font-weight:300;
  letter-spacing: -0.01em;
  color:#000000;
}

  .montylegal {
  font-family:'Avenir Next', sans-serif;
  font-size:11px;
  line-height:20px;
  font-weight:300;
  letter-spacing: 0.00em;
  color:#000000;
}

.divhover {
  background-color:#ffffff;
  border-radius:5px;
  border:1px #ffffff solid;
  transition: all 0.2s ease;
  background-color:#ffffff;
  color:#333333;	
}

.divhover:hover {
  background-color:#e76655;
  color:#ffffff;
}

::placeholder {
  font-family:'Montserrat', sans-serif;
  font-size:13px;
  font-weight:300;
  /* color: ''; */
  letter-spacing:0.01em;
}

input[type=text], input[type=tel] {
  font-family:'Avenir Next', sans-serif;
  font-size:15px;
  font-weight:500;
  /* color:#<?php echo $discolor ?>; */
  letter-spacing:0.01em;
  padding:5px;
  /* border:1px #<?php echo $discolor ?> solid; */
  border-radius:5px;
  box-shadow:none;
  -webkit-box-shadow:none;
  -moz-box-shadow:none;
  padding:15px;
  background:#1e98de;
}

sup {
  font-family:'Avenir Next', sans-serif;
  font-size:14px;
  font-weight:300;
  color:#000000;
  vertical-align:super;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
.mob {
  display:block;
}
.tab {
  display:none;
}
.pc {
  display:none;
}

.storypad {
   padding:20px 40px 20px 40px;
}

.arc {
  width:100%;
  min-width:270px;
  max-width:280px;
}


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
.mob {
  display:none;
}
.tab {
  display:block;
}
.pc {
  display:none;
}

.storypad {
   padding:40px 75px 40px 75px;
}

.arc {
  width:60%;
  min-width:320px;
  max-width:360px;
}


 
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
.mob {
  display:none;
}
.tab {
  display:none;
}
.pc {
  display:block;
}

.storypad {
   padding:60px 100px 60px 100px;
}

.arc {
  width:70%;
  min-width:580px;
  max-width:620px;
}
  
}

button:disabled {
  opacity: .5;
}