@font-face {
     font-family: 'Avenir Next';
     src: local('font/Avenir Next Light'), local('font/Avenir-Next-Light'),
          url('./fonts/AvenirNextCyr-Light.woff2') format('woff2'),
          url('./fonts/AvenirNextCyr-Light.woff') format('woff'),
          url('./fonts/AvenirNextCyr-Light.ttf') format('truetype');
     font-weight: 300;
     font-style: normal;
  }

@font-face {
     font-family: 'Avenir Next';
     src: local('font/Avenir Next Medium'), local('font/Avenir-Next-Medium'),
          url('./fonts/AvenirNextCyr-Medium.woff2') format('woff2'),
          url('./fonts/AvenirNextCyr-Medium.woff') format('woff'),
          url('./fonts/AvenirNextCyr-Medium.ttf') format('truetype');
     font-weight: 500;
     font-style: normal;
  }

@font-face {
     font-family: 'Avenir Next';
     src: local('Avenir Next Demi'), local('Avenir-Next-Demi'),
          url('./fonts/AvenirNextCyr-Demi.woff2') format('woff2'),
          url('./fonts/AvenirNextCyr-Demi.woff') format('woff'),
          url('./fonts/AvenirNextCyr-Demi.ttf') format('truetype');
     font-weight:600;
     font-style: normal;
  }

@font-face {
     font-family: 'Avenir Next';
     src: local('font/Avenir Next Bold'), local('font/Avenir-Next-Bold'),
          url('./fonts/AvenirNextCyr-Bold.woff2') format('woff2'),
          url('./fonts/AvenirNextCyr-Bold.woff') format('woff'),
          url('./fonts/AvenirNextCyr-Bold.ttf') format('truetype');
     font-weight: 700;
     font-style: normal;
 }